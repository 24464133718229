import * as msal from '@azure/msal-browser'
import axios from 'axios'

let msalInstance = null

let pluginOptions = null

// eslint-disable-next-line @typescript-eslint/class-name-casing
export default class msalPlugin extends msal.PublicClientApplication {

  static install(Vue, msalConfig = {}) {
    if (!msalConfig) {
      throw new Error('MsalPluginOption must be specified')
    }
    this.initialize(msalConfig)

    pluginOptions = msalConfig

    // msalInstance = this
    Vue.prototype.$msal = Vue.observable(msalInstance)
  }

  static initialize(options) {
    const msalConfig = {
      auth: {
        clientId: options.clientId,
        authority: options.loginAuthority,
        knownAuthorities: [options.knownAuthority]
      },
      cache: {
        cacheLocation: 'localStorage'
      },
      system: {
        loggerOptions: {
          loggerCallback: (level, message, containsPii) => {
            if (containsPii) {
              return
            }
            switch (level) {
            case msal.LogLevel.Error:
              console.error(message)

              return
            case msal.LogLevel.Info:
              console.info(message)

              return
            case msal.LogLevel.Verbose:
              console.debug(message)

              return
            case msal.LogLevel.Warning:
              console.warn(message)

              return
            }
          },
          piiLoggingEnabled: true,
          logLevel: msal.LogLevel.Verbose
        }
      }
    }

    msalInstance = new msalPlugin(msalConfig)
  }
  
  async signIn() {
    try {
      const loginRequest = {
        scopes: ['openid', 'profile', 'offline_access', pluginOptions.apiScope],
        authority: pluginOptions.loginAuthority
      }
      const loginResponse = await msalInstance.loginPopup(loginRequest)
    }
    catch (err) {
      if (err.errorMessage && err.errorMessage.indexOf('AADB2C90118') > -1) {
        try {
            
          const resetRequest = {
            scopes: ['openid', 'profile', 'offline_access', pluginOptions.apiScope],
            authority: pluginOptions.passwordAuthority
          }

          const passwordResetResponse = await msalInstance.loginPopup(resetRequest)

        } catch (passwordResetError) {
          console.error(passwordResetError)
        }
      }
    }
  }

  async signOut() {
    await msalInstance.logout()
  }

  async acquireToken() {
    const request = {
      account: msalInstance.getAllAccounts()[0],
      // TODO: Add API scope here
      scopes: [pluginOptions.apiScope]
    }

    try {
      const response = await msalInstance.acquireTokenSilent(request)

      return response.accessToken
    }
    catch (error) {
      if (error instanceof msal.InteractionRequiredAuthError) {
        return msalInstance.acquireTokenPopup(request).catch((popupError) => {
          console.error(popupError)
        })
      }

      return false
    }
  }
    
  async getHeaders() {
    const accessToken = await msalInstance.acquireToken()

    return {
      Authorization: 'Bearer ' + accessToken,
      'Ocp-Apim-Subscription-Key': process.env.VUE_APP_BANNERS_API_KEY
    }
  }

  getUsersApiUrl() {
    return `${process.env.VUE_APP_BANNERS_API_URL}users/user/${process.env.VUE_APP_BANNERS_API_ENTITY}`
  }

  async getUserDetails() {
    try {
      const response = await axios ({
        method: 'get',
        url: this.getUsersApiUrl(),
        headers: await this.getHeaders()
      })

      if (response.status === 200) {
        return response.data
      }

      return null
    }
    catch (error) {
      console.log(error)
      this.errored = true

      return null
    }        
  }
}

export { msalInstance }