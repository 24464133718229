import icons from './icons'
import theme from './theme'
import analytics from './analytics'

export default {
  // product display information
  product: {
    name: 'Berea Adventist Church',
    version: 'v1.0.1'
  },

  // icon libraries
  icons,

  // theme configs
  theme,

  // analytics configs
  analytics,

  msalConfig: {
    clientId: process.env.VUE_APP_MSAL_CLIENT_ID,
    loginAuthority:  process.env.VUE_APP_MSAL_LOGIN_AUTHORITY,
    passwordAuthority: process.env.VUE_APP_MSAL_PASSWORD_RESET_AUTHORITY,
    knownAuthority: process.env.VUE_APP_MSAL_KNOWN_AUTHORITY,
    apiScope: process.env.VUE_APP_MSAL_API_SCOPE
  }
}
