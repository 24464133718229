import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export const routes = [{
  path: '/',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'home-page',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/hope/HomePage.vue')
  }]
},{
  path: '/story',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'story-page',
    component: () => import(/* webpackChunkName: "story-page" */ '@/pages/hope/StoryPage.vue')
  }]
},{
  path: '/story/foundation',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'foundation-page',
    component: () => import(/* webpackChunkName: "foundation-page" */ '@/pages/hope/FoundationPage.vue')
  }]
},{
  path: '/children',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'children-page',
    component: () => import(/* webpackChunkName: "children-page" */ '@/pages/hope/ChildrenPage.vue')
  }]
},{
  path: '/children/mupa',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'mupa-page',
    component: () => import(/* webpackChunkName: "mupa-page" */ '@/pages/hope/MupaPage.vue')
  }]
},{
  path: '/children/luanda',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'luanda-page',
    component: () => import(/* webpackChunkName: "luanda-page" */ '@/pages/hope/LuandaPage.vue')
  }]
}, {
  path: '/sponsorships',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'sponsorships-page',
    component: () => import(/* webpackChunkName: "sponsorships-page" */ '@/pages/hope/SponsorPage.vue')
  }]
}, {
  path: '/projects',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'projects-page',
    component: () => import(/* webpackChunkName: "projects-page" */ '@/pages/hope/ProjectsPage.vue')
  }]
},{
  path: '/projects/solar',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'solar-page',
    component: () => import(/* webpackChunkName: "solar-page" */ '@/pages/hope/SolarPage.vue')
  }]
},
{
  path: '/projects/scholarships',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'scholarships-page',
    component: () => import(/* webpackChunkName: "scholarships-page" */ '@/pages/hope/ScholarshipsPage.vue')
  }]
},
{
  path: '/news',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'news-page',
    component: () => import(/* webpackChunkName: "news-page" */ '@/pages/hope/NewsPage.vue')
  }]
},
{
  path: '/events',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'events-page',
    component: () => import(/* webpackChunkName: "events-page" */ '@/pages/hope/EventsPage.vue')
  }]
},
{
  path: '/covid',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'covid-page',
    component: () => import(/* webpackChunkName: "events-page" */ '@/pages/hope/CovidPage.vue')
  }]
},
{
  path: '/donate',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'donate-page',
    component: () => import(/* webpackChunkName: "donate-page" */ '@/pages/hope/DonatePage.vue')
  }]
},{
  path: '/contact',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'contact-page',
    component: () => import(/* webpackChunkName: "contact-page" */ '@/pages/hope/ContactPage.vue')
  }]
}, {
  path: '/terms',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'terms',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/TermsPage.vue')
  }]
}, {
  path: '/privacy',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'privacy',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/PrivacyPage.vue')
  }]
},{
  path: '/admin',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout'),
  children: [{
    path: 'banners',
    name: 'banners-page',
    component: () => import(/* webpackChunkName: "banners-page" */ '@/pages/admin/BannersPage'),
    meta: {
      title: 'Banners'
    }
  },{
    path: 'bulletin',
    name: 'bulletin-page',
    component: () => import(/* webpackChunkName: "bulletin-page" */ '@/pages/admin/BulletinPage'),
    meta: {
      title: 'Bulletin'
    }
  },{
    path: '/',     
    component: () => import(/* webpackChunkName: "bulletin-page" */ '@/pages/admin/AdminPage')
  }]
},  {
  path: '*',
  component: () => import(/* webpackChunkName: "layout-error" */ '@/layouts/ErrorLayout.vue'),
  children: [{
    path: '',
    name: 'error',
    component: () => import(/* webpackChunkName: "error" */ '@/pages/error/NotFoundPage.vue')
  }]
}]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL || '/',
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  routes
})

/**
 * Before each route update
 */
router.beforeEach((to, from, next) => {
  return next()
})

/**
 * After each route update
 */
router.afterEach((to, from) => {
})

export default router
